import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 
import { DateRange } from 'react-date-range';
import { es } from 'date-fns/locale';
import { format } from 'date-fns';
import { FaCircle } from 'react-icons/fa';

const DatePicker = ({defaultValue, readOnly, onSelectRange, classNames, blockedDays}) => {
    const colors = ["#FE6830"];
    const [disabledDates, setDisabledDates] = useState([new Date()]);

    useEffect(() => {
      var disabled_dates = [new Date()];
      blockedDays.map((d) => {
        disabled_dates.push(new Date(`${d.fecha}T12:00:00`));
      });
      setDisabledDates(disabled_dates);
    }, []);

    const [datesRange, setDatesRange] = useState([
        defaultValue
    ]);

    const customDayContent = (day) =>  {
        let extraDot = null;
        if (blockedDays.some(e => e.fecha === format(day, 'yyyy-MM-dd') && e.tipo === 2)) {
          extraDot = (
            <div
              style={{
                height: "5px",
                width: "5px",
                borderRadius: "100%",
                background: "gray",
                position: "absolute",
                top: 2,
                right: 2,
              }}
            />
          )
        }

        if (blockedDays.some(e => e.fecha === format(day, 'yyyy-MM-dd') && e.tipo === 1)) {
          extraDot = (
            <div
              style={{
                height: "5px",
                width: "5px",
                borderRadius: "100%",
                background: "orange",
                position: "absolute",
                top: 2,
                right: 2,
              }}
            />
          )
        }

        return (
          <div>
            {extraDot}
            <span>{format(day, "d")}</span>
          </div>
        )
    }

    return(
      <div className='flex flex-col w-[325px]'>
          <DateRange
              editableDateInputs={true}
              onChange={item => {setDatesRange([item.selection]); onSelectRange(item.selection)}}
              moveRangeOnFirstSelection={false}
              ranges={datesRange}
              direction="vertical"
              locale={es}
              rangeColors={colors}
              minDate={new Date()}
              weekStartsOn={0}
              disabledDates={disabledDates}
              dayContentRenderer={customDayContent}
          />
          
          <div className='text-[8px] text-secondary-300 flex px-3'><FaCircle/><span className='text-sm text-black ml-2'>Día bloqueado</span></div>

          <div className='text-[8px] text-orange-400 flex px-3'><FaCircle/><span className='text-sm text-black ml-2'>Día apartado para otra renta</span></div>
          
      </div>
    )
}

export default DatePicker;
