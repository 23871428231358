import React, {Fragment, useState, useEffect} from "react";
import Layout from "../components/UI/Layout";
import Container from "../components/UI/Container";
import CartGroup from "../components/UI/ShoppingCartElement/CartGroup.js";
import { merent_instance } from "../helpers/axios_helper";
import toast from 'react-hot-toast';

const ShoppingCart = () => {
  const [equiposRentaLocal, setEquiposRentaLocal] = useState([]);
  const [equiposRentaRemoto, setEquiposRentaRemoto] = useState([]);
  const [actualizar, setActualizar] = useState(0);

  useEffect(() => {
    cargarCarritoLocal();
    cargarCarritoRemoto();
  }, [actualizar])

  const cargarCarritoLocal = () => {
    const carrito = localStorage.getItem("carrito");
    if(carrito) {
      if(carrito.length > 0){
        const carrito_obj = JSON.parse(carrito);
        var result = carrito_obj.reduce(function(r, o) {
          var key = o.rentero.nombreEmpresa + ' (' + o.fechaInicio + ' - ' + o.fechaFin + ')';
          
          var found = r.filter((i) => {if(i.key == key){return true}});
          if(found.length === 0) {
            var obj = {key: key, equipos: [], precioTotal: parseFloat(o.subtotal), estado_renta: 0, direccion_rentero: o.rentero.direccion.direccionCompleta};
            obj['equipos'].push(o);
            r.push(obj);
          } else {
            r.map((i) => {
              if(i.key == key){
                i.equipos.push(o);
                i.precioTotal += parseFloat(o.subtotal);
              }
            })
          }
        
          return r;
        }, []);
        setEquiposRentaLocal(result);
      }
    }
  }

  const cargarCarritoRemoto = () => {
    const usr_id = localStorage.getItem("usr_id");
    if(usr_id !== undefined && usr_id !== null && usr_id > 0){
      merent_instance().get(`/api/rentas/cliente/${usr_id}`)
      .then(({data}) => {
        setEquiposRentaRemoto(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error al consultar rentas");
      })
    }
  }

  return (
    <Layout>
      <Container> 
          <div className="p-5 flex-col text-lg rounded-xl bg-white shadow-lg">
            {
              equiposRentaRemoto !== undefined && equiposRentaRemoto !== null && equiposRentaRemoto.length > 0 ?
              <Fragment>
                <div className="flex justify-center font-semibold text-3xl my-4 text-primary-500">
                    Equipos en proceso de renta
                </div>
                <div className="mb-5">  
                {
                  equiposRentaRemoto.map((grupo, idx) => {
                    const grupo_obj = {
                      idRenta: grupo.id,
                      equipos: grupo.equipoRenta,
                      estadoRentaRef: grupo.estadoRentaRef,
                      precioTotal: grupo.precioTotal,
                      precioFlete: grupo.precioFlete,
                      direccionRentero: grupo.rentero.direccion.direccionCompleta,
                      imagen: grupo.equipoRenta[0].equipo.imagenEquipos[0]
                    }
                    return (
                      <CartGroup 
                        setActualizar={setActualizar}
                        actualizar={actualizar}
                        nombre_grupo={`# Renta: ${grupo.id} - Empresa: ${grupo.rentero.nombreEmpresa} (${grupo.fechaInicio.substring(0,10)} - ${grupo.fechaFin.substring(0,10)})`}
                        grupo={grupo_obj}
                        idx={`renta_${grupo.id}`}
                        key={`renta_${grupo.id}`}
                        showHr={idx + 1 === equiposRentaRemoto.length ? false : true}
                        esRemoto={true}
                      />
                    )
                  })
                }
                <br/>
                </div>
              </Fragment>
              :
              ''
            }
            {
            equiposRentaLocal !== undefined && equiposRentaLocal !== null && equiposRentaLocal.length > 0 ?
            <Fragment>
              <div className="flex justify-center font-semibold text-3xl my-4 text-primary-500">
                  Equipos en proceso de renta
              </div>
              <div className="mb-5">  
              {
                equiposRentaLocal !== undefined && equiposRentaLocal !== null && equiposRentaLocal.length > 0 ?
                  equiposRentaLocal.map((grupo, idx) => {
                    return (
                      <CartGroup 
                        setActualizar={setActualizar}
                        actualizar={actualizar}
                        nombre_grupo={`Empresa: ${grupo.key}`}
                        grupo={grupo}
                        idx={idx}
                        key={idx}
                        showHr={idx + 1 === equiposRentaLocal.length ? false : true}
                        esRemoto={false}
                      />
                    )
                  })
                :
                ''
              }
              <br/>
              </div>
            </Fragment>
            :
            ''
            }
          </div>   
      </Container>
    </Layout>
  )
}

export default ShoppingCart;
