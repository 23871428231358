import React from 'react';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';

const Address = ({data, editFn, deleteFn, esPrincipal}) => {
    const {
        calle,
        ciudad,
        colonia,
        direccionCompleta,
        id,
        latitud,
        longitud,
        estado,
        codigoPostal,
        nombre,
        numeroInterior,
        numeroExterior
    } = data;
    return(
        <div className={`grid grid-cols-6 md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-y-0 mb-1 p-3 text-base bg-gray-100 shadow-lg border-b-2 border-gray-200`}>
            <div className="col-span-4 md:col-span-10 lg:col-span-11 xl:col-span-11">
                {
                    esPrincipal !== undefined && esPrincipal !== null && esPrincipal === true ?
                        <h6 className='text-primary-500'>Dirección principal</h6>
                    :
                        <h6 className='text-secondary-200'>Dirección secundaria</h6>
                }
            </div>
            <div className="col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                {
                    editFn !== undefined && editFn !== null ?
                        <button className="bg-secondary-50 hover:bg-secondary-100 text-sm text-white p-1 mx-1 rounded-sm" 
                            type="button" 
                            onClick={editFn}>
                            <FaRegEdit/>
                        </button>
                    :
                        ''
                }
                {
                    deleteFn !== undefined && deleteFn !== null ?
                        <button className="bg-secondary-50 hover:bg-secondary-100 text-sm text-white p-1 mx-1 rounded-sm" 
                            type="button" 
                            onClick={deleteFn}>
                            <FaTrashAlt/>
                        </button>
                    :
                        ''
                }
            </div>
            <div className="col-span-6 md:col-span-12 lg:col-span-12 xl:col-span-12 flex justify-left items-center">
                <label className='text-primary-300 font-semibold mr-2'>Nombre de direccion: </label> {nombre}
            </div>
            <div className="col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-4 flex justify-left items-center">
                <label className='text-primary-300 font-semibold mr-2'>Calle: </label> {calle}
            </div>
            <div className="col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-4 flex justify-left items-center">
                <label className='text-primary-300 font-semibold mr-2'>Núm. Exterior: </label> {numeroExterior}
            </div>
            <div className="col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-4 flex justify-left items-center">
                <label className='text-primary-300 font-semibold mr-2'>Numero Interior: </label> {numeroInterior}
            </div>
            <div className="col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-4 flex justify-left items-center">
                <label className='text-primary-300 font-semibold mr-2'>Colonia: </label> {colonia}
            </div>
            <div className="col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-4 flex justify-left items-center">
                <label className='text-primary-300 font-semibold mr-2'>Estado: </label> {ciudad.estado.nombre}
            </div>
            <div className="col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-4 flex justify-left items-center">
                <label className='text-primary-300 font-semibold mr-2'>Ciudad: </label> {ciudad.nombre}
            </div>
            <div className="col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-4 flex justify-left items-center">
                <label className='text-primary-300 font-semibold mr-2'>Codigo Postal: </label> {codigoPostal}
            </div>
        </div>
    )
}

export default Address;