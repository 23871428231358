import React, {useState, useEffect, createRef} from 'react'
import Image from '../LazyLoadImage';
import NotFound from "../../../images/image-not-found.png"

function Carousel({images}) {
    const [currentImage, setCurrentImage] = useState(0);
    // We are using react ref to 'tag' each of the images. Below will create an array of
    // objects with numbered keys. We will use those numbers (i) later to access a ref of a
    // specific image in this array.
    const refs = images.reduce((acc, val, i) => {
      acc[i] = createRef();
      return acc;
    }, {});
  
    const scrollToImage = i => {
      console.log("refs[i]", refs[i])
      // First let's set the index of the image we want to see next
      setCurrentImage(i);
      // Now, this is where the magic happens. We 'tagged' each one of the images with a ref,
      // we can then use built-in scrollIntoView API to do eaxactly what it says on the box - scroll it into
      // your current view! To do so we pass an index of the image, which is then use to identify our current
      // image's ref in 'refs' array above.
      if(refs[i].current != null)
      refs[i].current.scrollIntoView({
        //     Defines the transition animation.
        behavior: 'smooth',
        //      Defines vertical alignment.
        block: 'nearest',
        //      Defines horizontal alignment.
        inline: 'start',
      });
    };
  
    // Some validation for checking the array length could be added if needed
    const totalImages = images.length;
  
    // Below functions will assure that after last image we'll scroll back to the start,
    // or another way round - first to last in previousImage method.
    const nextImage = () => {
      if (currentImage >= totalImages - 1) {
        scrollToImage(0);
      } else {
        scrollToImage(currentImage + 1);
      }
    };
  
    const previousImage = () => {
      if (currentImage === 0) {
        scrollToImage(totalImages - 1);
      } else {
        scrollToImage(currentImage - 1);
      }
    };
  
    // Tailwind styles. Most importantly notice position absolute, this will sit relative to the carousel's outer div.
    const arrowStyle =
      'absolute text-white text-2xl z-10 bg-primary-300 hover:bg-primary-400 h-10 w-10 rounded-full opacity-75 flex items-center justify-center';
  
    // Let's create dynamic buttons. It can be either left or right. Using
    // isLeft boolean we can determine which side we'll be rendering our button
    // as well as change its position and content.
    const sliderControl = isLeft => (
      <button
        type="button"
        onClick={isLeft ? previousImage : nextImage}
        className={`${arrowStyle} ${isLeft ? 'left-2' : 'right-2'}`}
        style={{ top: '40%' }}
      >
        <span role="img" aria-label={`Arrow ${isLeft ? 'left' : 'right'}`}>
          {isLeft ? '◀' : '▶'}
        </span>
      </button>
    );
  
    return (
    // Images are placed using inline flex. We then wrap an image in a div
    // with flex-shrink-0 to stop it from 'shrinking' to fit the outer div.
    // Finally the image itself will be 100% of a parent div. Outer div is
    // set with position relative, so we can place our cotrol buttons using
    // absolute positioning on each side of the image.
    <div className="relative w-full">
        <div className="inline-flex overflow-x-hidden snap-x">
        {sliderControl(true)}
        {images.map((img, i) => {  
          {
            return img !== undefined && img !== null && img !== "" ?
              <Image
                  src={img.url}
                  alt="Imagen Equipo MeRent"
                  className={"h-100 m-0"}
                  key={i}
              />
              :
              <Image
                  src={NotFound}
                  alt="Imagen no encontrada"
                  key={i}
                  className={"object-cover h-100 w-screen m-0"}
              />
          }
        })}
        {sliderControl()}
        </div>
    </div>
    );
}

export default Carousel;
