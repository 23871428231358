import React, {Fragment, useState, useEffect} from "react";
import ShoppingCartElement from "./index";
import BasicButton from "../Buttons/BasicButton";
import toast from 'react-hot-toast';
import Modal from '../Modal';
import { Link, useNavigate } from "react-router-dom";
import { merent_instance } from "../../../helpers/axios_helper";
import BasicInput from "../Inputs/BasicInput";
import Address from '../Address';
import Form from "../Form";
import { form_fields_perfil_direcciones } from '../../../helpers/form_fields';

const CartGroup = ({setActualizar, actualizar, grupo, idx, nombre_grupo, showHr, esRemoto}) => {
    const {equipos, precioTotal, estadoRentaRef, idRenta, imagen} = grupo;
    
    const navigation = useNavigate();

    const [direcciones, setDirecciones] = useState([]);
    const [direccion, setDireccion] = useState({});

    const [showLogin, setShowLogin] = useState(false);
    const [showSolicitarFlete, setShowSolicitarFlete] = useState(false);
    const [showNoSolicitarFlete, setShowNoSolicitarFlete] = useState(false);
    const [showAceptarFlete, setShowAceptarFlete] = useState(false);
    const [showCancelarRenta, setShowCancelarRenta] = useState(false);

    const [showPago, setShowPago] = useState(false);
    const [dataPago, setDataPago] = useState({});

    const [estados, setEstados] = useState([]);
    const [ciudades, setCiudades] = useState([]);

    const [direccionFields, setDireccionFields] = useState({
        id: 0,
        nombre: "",
        direccionCompleta: '',
        calle: '',
        numeroInterior: '',
        numeroExterior: '',
        colonia: '',
        ciudadRef: 0,
        estadoRef: 0,
        codigoPostal: 0,
        latitud: 0,
        longitud: 0,
        isPrincipal: true
    });

    useEffect(() => {
        cargarDireccionesCliente();
    }, []);

    const cargarDireccionesCliente = () => {
        const usr_id = localStorage.getItem("usr_id");
        if(usr_id !== null && usr_id > 0)
        {
            merent_instance().get(`/api/clientes/${usr_id}/direcciones`).then(({data}) => {
                var dirs = data.map((dir) => {
                    if(dir.isPrincipal){
                        setDireccion(dir);
                    }
                    return {value: dir.direccionRef, label: dir.direccion.nombre, data: dir}
                });
                
                setDirecciones(dirs);
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                cargaEstados();
            });
        }
    }

    const cambiarDireccion = (value) => {
        var dir_selectec = direcciones.filter((dir) => {
            if(dir.value == value){
                return true;
            }
        });
        
        if(dir_selectec !== null && dir_selectec.length > 0){
            setDireccion(dir_selectec[0].data);
        }
        else{
            setDireccion(null);
        }
    }

    const showModal = (myfuncion) => {
        const usr_id = localStorage.getItem("usr_id");
        if(usr_id === undefined || usr_id === null || usr_id === 0)
        {
            setShowLogin(true);
        }
        else{
            myfuncion(true);
        }
    }

    const solicitarRenta = (estado) => {
        const usr_id = localStorage.getItem("usr_id");
        const equipos_renta = equipos.map(x => {
            return {
                idEquipo: x.id,
                precioTotal: x.subtotal,
                aditamentos: []
            }
        });
        const obj_renta = {
            clienteRef: usr_id,
            renteroRef: equipos[0].rentero.id,
            direccionEntregaRef: direccion.direccionRef,
            fechaInicio: equipos[0].datesRange.startDate.substring(0,10),
            fechaFin: equipos[0].datesRange.endDate.substring(0,10),
            precioTotal: precioTotal,
            descuentoApp: precioTotal * .15,
            equipos: equipos_renta,
            pago: {
                concepto: "Pago de maquinaria renta #10000",
                tipoPagoRef: 2,
                metodoPagoClienteRef: 1,
                statusPagoRef: 1
            },
            estadoRentaRef: estado
        }
        merent_instance().post(`/api/rentas`, obj_renta)
        .then(({data}) => {
            toast.success(data);
            borrarCarrito();
            setShowSolicitarFlete(false);
            setShowNoSolicitarFlete(false);
        })
        .catch(({response}) => {
            console.log(response);
            toast.error(response.data);
        });
    }

    const borrarCarrito = () => {
        let carrito_actual = JSON.parse(localStorage.getItem("carrito"));
        carrito_actual = carrito_actual.filter((_, index) => index !== idx);
        localStorage.setItem("carrito", JSON.stringify(carrito_actual));
        setActualizar(carrito_actual);
    }

    const aceptarFlete = () => {
        merent_instance().patch(`/api/rentas/${idRenta}/3`)
        .then(({data}) => {
            toast.success(data);
            setShowAceptarFlete(false);
            setActualizar(Math.random() * 100);
        })
        .catch((error) => {
            console.log(error);
            toast.error("Error al actualizar la renta, favor de intentar más tarde nuevamente.")
        })
    }

    const solicitarPago = () => {
        console.log("Solicitar pago")
        merent_instance().patch(`/api/rentas/${idRenta}/5`)
        .then(({data}) => {
            console.log(data);
            setShowPago(true);
            setDataPago(data);
            setActualizar(Math.random() * 100);

        })
        .catch((error) => {
            console.log(error);
            toast.error("Error al actualizar la renta, favor de intentar más tarde nuevamente.")
        })
    }

    const procesoCheckout = () => {
        window.open(dataPago.url, '_blank', 'noreferrer');
        setShowPago(false);
        setActualizar(Math.random() * 100);

    }

    const cancelarRenta = () => {
        merent_instance().patch(`/api/rentas/${idRenta}/8`)
        .then(({data}) => {
            toast.success(data);
            setActualizar(Math.random() * 100);

        })
        .catch((error) => {
            console.log(error);
            toast.error("Error al cancelar la renta, favor de intentar más tarde nuevamente.")
        })
    }

    const modifiarEstado = (estado) => {
        setDireccionFields(estado);
        if(estado.estadoRef != 0){
            cargaCiudades(estado.estadoRef);
        }
    }

    const cargaEstados = () => {
        merent_instance().get("api/direcciones/estados/false").then(({data}) => {
            const new_estados = data.map(estado => {
                return {
                    id: estado.id,
                    value: estado.id,
                    label: estado.nombre
                }
            });
            setEstados(new_estados);

        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
        })
    }

    const cargaCiudades = (estadoRef) => {
        merent_instance().get(`api/direcciones/ciudades/${estadoRef}/false`).then(({data}) => {
            const new_ciudades = data.map(ciudad => {
                return {
                    id: ciudad.id,
                    value: ciudad.id,
                    label: ciudad.nombre
                }
            });
            setCiudades(new_ciudades);
        }).catch((error) => {
            console.log(error);
            toast.error("Hubo un error al consultar información");
        })
    }

    const saveDireccion = () => {
        //Nueva Direccion
        const cliente_id = localStorage.getItem("usr_id");
        if(!cliente_id){
            toast.error("Su sesión ha expirado, favor de volver a iniciar");
            localStorage.setItem("redireccionar_carrito", true)
            navigation("/login");
            return;
        }
        const new_direccion = {
            clienteRef: cliente_id,
            isPrincipal: direccionFields.isPrincipal,
            direccion: {
                ...direccionFields
            }
        }

        merent_instance().post("/api/direcciones", new_direccion).then(({data}) => {
            toast.success(data);
            resetFields();
        }).catch(error => {
            console.log(error);
            toast.error("Ha habido un error al guardar su nueva dirección, favor de intentarlo de nuevo");
        }).finally(() => {
            cargarDireccionesCliente();
        })
    };
    
    const resetFields = () => {
        setDireccionFields({
          id: 0,
          nombre: "",
          direccionCompleta: '',
          calle: '',
          numeroInterior: '',
          numeroExterior: '',
          colonia: '',
          ciudadRef: 0,
          estadoRef: 0,
          codigoPostal: 0,
          latitud: 0,
          longitud: 0,
          isPrincipal: true
        });
    }

    return (
        <Fragment>
            <div key={`grupo_carrito_${idx}`} className="mb-10">
                <h4 className="mx-5 my-3">{nombre_grupo}</h4>
                {
                    equipos !== undefined && equipos !== null && equipos.length > 0 ?
                    esRemoto ? 
                    equipos.map((equipo, idx) => {
                        return (
                        <ShoppingCartElement 
                            key={equipo.equipo.id} 
                            title={equipo.equipo.nombre} 
                            description={equipo.equipo.descripcion} 
                            precio={equipo.precioTotal} 
                            link={`/${equipo.equipo.nombre}`}
                            image={imagen != null ? imagen : null}
                            index={idx}
                            setNewEquipos={setActualizar}
                            showRemove={estadoRentaRef === 0 ? true : false}
                        /> 
                        )
                    })
                    :
                    equipos.map((equipo, idx) => {
                        return (
                        <ShoppingCartElement 
                            key={equipo.equipo_id} 
                            title={equipo.nombre} 
                            description={`${equipo.datesRange.startDate.substring(0,10)} - ${equipo.datesRange.endDate.substring(0,10)}`} 
                            precio={equipo.subtotal} 
                            link={`/${equipo.nombre}`}
                            image={equipo.imagenEquipos[0]}
                            index={idx}
                            setNewEquipos={setActualizar}
                            showRemove={estadoRentaRef === 0 ? true : false}
                        /> 
                        )
                    })
                    :
                    ''
                }
                <div className="flex justify-end text-secondary-300 font-normal pt-3">
                    <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 text-center">
                        <span className="font-semibold text-xl mt-4 text-primary-500">Total de la compra:</span> $ {precioTotal === undefined || precioTotal === null ? 0 : precioTotal.toFixed(2)}
                    </div>
                    
                </div>
                {
                    estadoRentaRef === undefined ?
                        <div className="grid grid-cols-3 text-secondary-300 font-normal gap-x-3 pb-3">
                            <div className="col-span-3 md:col-span-2 lg:col-span-2 xl:col-span-2 xxl:col-span-2 text-left">
                                <h2 className="font-semibold text-xl my-4 text-primary-500">Flete de los equipos</h2>
                                <p className="text-base">En MERENT te facilitamos la entrega de tus equipos hasta la dirección que los necesites. Puedes solicitar una cotización de los precios de flete.</p>
                                <div className="grid grid-cols-1 gap-x-3">
                                    <BasicButton type="button" text="Solicitar sevicio de flete" className="bg-primary-400 text-white text-center hover:bg-primary-500 text-base col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 mt-3" clickFn={() =>{ showModal(setShowSolicitarFlete) }}/>
                                    {/* <BasicButton type="button" text="Yo me encargo del flete" className="bg-secondary-400 text-white text-center hover:bg-secondary-500 text-base col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 mt-3" clickFn={() =>{ showModal(setShowNoSolicitarFlete) }}/> */}
                                </div>
                            </div>
                            <div className="col-span-3 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 text-center">
                                <h5 className="text-lg mt-5">Dirección del Rentero:</h5>
                                <p>{grupo.direccion_rentero}</p>
                            </div>
                        </div>
                    : estadoRentaRef === 1 ?
                        <div className="grid grid-cols-3 text-secondary-300 font-normal gap-x-3 pb-3">
                            <div className="col-span-3 md:col-span-2 lg:col-span-2 xl:col-span-2 xxl:col-span-2 text-left">
                                <h2 className="font-semibold text-xl my-4 text-primary-500">En espera de confirmación por rentero</h2>
                                <p className="text-base">La solicitud de renta se ha enviado al rentero, una ves confirmada la información del equipo y la disponibilidad de la maquina, se solicitará el flete al administrador.</p>
                                <div className="">
                                    <BasicButton type="button" text="Cancelar solicitud" className="bg-secondary-400 text-white text-center hover:bg-secondary-500 text-base mt-3" clickFn={() => showModal(setShowCancelarRenta)}/>
                                </div>
                            </div>
                            <div className="col-span-3 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 text-center">
                                <h5 className="text-lg mt-5">Dirección del Rentero:</h5>
                                <p>{grupo.direccionRentero}</p>
                            </div>
                        </div>
                    : estadoRentaRef === 2 ?
                        <div className="grid grid-cols-3 text-secondary-300 font-normal gap-x-3 pb-3">
                            <div className="col-span-3 md:col-span-2 lg:col-span-2 xl:col-span-2 xxl:col-span-2 text-left">
                                <h2 className="font-semibold text-xl my-4 text-primary-500">En espera de precio de flete</h2>
                                <p className="text-base">El precio de flete ha sido solicitado al Administrador. En cuanto lo tengamos disponible aparecerá en esta sección.</p>
                                <div className="">
                                    <BasicButton type="button" text="Cancelar solicitud" className="bg-secondary-400 text-white text-center hover:bg-secondary-500 text-base mt-3" clickFn={() => showModal(setShowCancelarRenta)}/>
                                </div>
                            </div>
                            <div className="col-span-3 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 text-center">
                                <h5 className="text-lg mt-5">Dirección del Rentero:</h5>
                                <p>{grupo.direccionRentero}</p>
                            </div>
                        </div>
                    : estadoRentaRef === 3 ?
                        <div className="grid grid-cols-3 text-secondary-300 font-normal gap-x-3 pb-3">
                            <div className="col-span-3 md:col-span-2 lg:col-span-2 xl:col-span-2 xxl:col-span-2 text-left">
                                <h2 className="font-semibold text-xl my-4 text-primary-500">En espera de confirmación de flete</h2>
                                <p className="text-base">El precio de flete propuesto por el Administrador es de:</p>
                                <h4 className="text-xl text-primary-400 text-center my-2">$ {grupo.precioFlete === undefined || grupo.precioFlete === null ? 0 : grupo.precioFlete.toFixed(2)}</h4>
                                <p className="text-base">Si estás de acuerdo con este precio, favor de confirmar para continuar con el proceso de renta, de lo contrario, cancelar la solicitud.</p>
                                <div className="grid grid-cols-2 gap-x-3">
                                    <BasicButton type="button" text="Aceptar precio de flete" className="bg-primary-400 text-white text-center hover:bg-primary-500 text-base col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 mt-3" clickFn={() =>{ showModal(setShowAceptarFlete) }}/>
                                    <BasicButton type="button" text="Cancelar solicitud" className="bg-secondary-400 text-white text-center hover:bg-secondary-500 text-base col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 mt-3" clickFn={() =>{ showModal(setShowCancelarRenta) }}/>
                                </div>
                            </div>
                            <div className="col-span-3 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 text-center">
                                <h5 className="text-lg mt-5">Dirección del Rentero:</h5>
                                <p>{grupo.direccionRentero}</p>
                            </div>
                        </div>
                    : estadoRentaRef === 4 ?
                        <div className="grid grid-cols-3 text-secondary-300 font-normal gap-x-3 pb-3">
                            <div className="col-span-3 md:col-span-2 lg:col-span-2 xl:col-span-2 xxl:col-span-2 text-left">
                                <h2 className="font-semibold text-xl my-4 text-primary-500">En espera de firma de contrato</h2>
                                <p className="text-base">Mantente atento de tu bandeja de entrada, incluida la carpeta de correos no deseados. 
                                <br/>
                                Vas a recibir un correo electronico con instrucciones para firmar el contrato de renta de servicios
                                <br/> 
                                Si ya recibiste el contrato, favor de firmarlo para poder continuar con el proceso de renta.
                                </p>
                                <div className="text-xs text-center">De no recibir el contrato en las primeras 24 horas posteriores a la confirmación puedes solicitar soporte a través de Whatsapp en el botón de la derecha inferior.</div>
                                <div className="">
                                    <BasicButton type="button" text="Cancelar solicitud" className="bg-secondary-400 text-white text-center hover:bg-secondary-500 text-base mt-3" clickFn={() => showModal(setShowCancelarRenta)}/>
                                </div>
                            </div>
                            <div className="col-span-3 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 text-center">
                                <h5 className="text-lg mt-5">Dirección del Rentero:</h5>
                                <p>{grupo.direccionRentero}</p>
                            </div>
                        </div>
                    : estadoRentaRef === 5 ?
                        <div className="grid grid-cols-3 text-secondary-300 font-normal gap-x-3 pb-3">
                            <div className="col-span-3 md:col-span-2 lg:col-span-2 xl:col-span-2 xxl:col-span-2 text-left">
                                <h2 className="font-semibold text-xl my-4 text-primary-500">Contrato firmado</h2>
                                <p className="text-base">El Administrador ha recibido el contrato firmado. Favor de continuar con el flujo para realizar el pago de tu renta .</p>
                                <div className="grid grid-cols-2 gap-x-3">
                                    <BasicButton type="button" text="Proceder al pago" clickFn={solicitarPago} className="bg-primary-400 text-white text-center hover:bg-primary-500 text-base col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 mt-3"/>
                                    <BasicButton type="button" text="Cancelar solicitud" className="bg-secondary-400 text-white text-center hover:bg-secondary-500 text-base col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 mt-3" clickFn={() =>{ showModal(setShowCancelarRenta) }}/>
                                </div>
                            </div>
                            <div className="col-span-3 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 text-center">
                                <h5 className="text-lg mt-5">Dirección del Rentero:</h5>
                                <p>{grupo.direccionRentero}</p>
                            </div>
                        </div>
                    : estadoRentaRef === 6 ?
                        <div className="grid grid-cols-3 text-secondary-300 font-normal gap-x-3 pb-3">
                            <div className="col-span-3 md:col-span-2 lg:col-span-2 xl:col-span-2 xxl:col-span-2 text-left">
                                <h2 className="font-semibold text-xl my-4 text-primary-500">En espera de confirmación de pago</h2>
                                <p className="text-base">El pago se encuentra en espera de confirmación, este proceso puede tardar unas horas.
                                <br/> 
                                Al confirmarse, verás la renta en la sección de <Link to="/perfil">Historial de Rentas</Link>
                                </p>
                                <div className="text-xs text-center">Si tienes alguna duda con respecto a tu pago, puedes solicitar soporte a través de Whatsapp en el botón de la derecha inferior.</div>
                                <div className="">
                                    <BasicButton type="button" text="Cancelar solicitud" className="bg-secondary-400 text-white text-center hover:bg-secondary-500 text-base mt-3" clickFn={() => showModal(setShowCancelarRenta)}/>
                                </div>
                            </div>
                            <div className="col-span-3 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 text-center">
                                <h5 className="text-lg mt-5">Dirección del Rentero:</h5>
                                <p>{grupo.direccionRentero}</p>
                            </div>
                        </div>
                    : estadoRentaRef === 7 ?
                    <div className="grid grid-cols-3 text-secondary-300 font-normal gap-x-3 pb-3">
                        <div className="col-span-3 md:col-span-2 lg:col-span-2 xl:col-span-2 xxl:col-span-2 text-left">
                            <h2 className="font-semibold text-xl my-4 text-secondary-500">Pago rechazado</h2>
                            <p className="text-base">No se pudo realizar el pago.
                            Puedes intentar pagar de nuevo, utilizar otro método de pago, o bien, cancelar la renta.
                            </p>
                            <div className="text-xs text-center">Si tienes alguna duda con respecto a tu pago, puedes solicitar soporte a través de Whatsapp en el botón de la derecha inferior.</div>
                            <div className="grid grid-cols-2 gap-x-3">
                                    <BasicButton type="button" text="Intentar pagar de nuevo" className="bg-primary-400 text-white text-center hover:bg-primary-500 text-base col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 mt-3"/>
                                    <BasicButton type="button" text="Cancelar solicitud" className="bg-secondary-400 text-white text-center hover:bg-secondary-500 text-base col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 mt-3" clickFn={() =>{ showModal(setShowCancelarRenta) }}/>
                                </div>
                        </div>
                        <div className="col-span-3 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 text-center">
                            <h5 className="text-lg mt-5">Dirección del Rentero:</h5>
                            <p>{grupo.direccionRentero}</p>
                        </div>
                    </div>
                    :
                    ''
                }
                {
                    showHr ?
                        <hr/>
                    :
                        ''
                }
                
            </div>

            <Modal className="max-w-md" title="Inicia sesión en MERENT" show={showLogin} setShow={setShowLogin}>
                <div className="mt-3 text-center">
                    A partir de este momento es necesario que te registres
                    <Link to="/login" style={{ textDecoration: 'none' }} onClick={()=>{localStorage.setItem("redireccionar_carrito", true)}}>
                        <BasicButton
                            type="button" 
                            text="Iniciar Sesión" 
                            className="mt-5"
                        />
                    </Link>
                </div>
            </Modal>

            <Modal className={direcciones === undefined || direcciones === null || direcciones.length === 0 ? "max-w-4xl" : "max-w-2xl"} title="Solicitar precio de flete" show={showSolicitarFlete} setShow={setShowSolicitarFlete}>
                {
                    direcciones === undefined || direcciones === null || direcciones.length === 0 ?
                        <div className="mt-3">
                            Ingresa la direccion a donde se tiene que enviar el equipo.
                            <div className="mt-3">
                            <Form 
                                formFields={form_fields_perfil_direcciones(estados, ciudades)} 
                                formState={direccionFields} 
                                formModifyState={modifiarEstado}
                                formSave={saveDireccion}
                                buttonText={direccionFields.id === 0 ? "Guardar" : "Actualizar"}
                            />
                            </div>
                        </div>
                    :
                        <div className="mt-3 text-left">
                            Ingresa la direccion a donde se tiene que enviar el equipo.
                            <br/>
                            Esto ayudará a calcular el precio de flete.
                            <div className="mr-5">
                                <BasicInput
                                    type="select" 
                                    placeholder="Seleccione" 
                                    defaultValue={direccion !== undefined && direccion !== null && direccion.direccionRef !== undefined ? direccion.direccionRef : 0}
                                    setValue={(e) => {cambiarDireccion(e.target.value)}}
                                    options={direcciones}
                                    disabled={false} 
                                    className="w-full"
                                />
                            </div>
                            
                            {
                                direccion !== undefined && direccion !== null && direccion.direccionRef !== undefined ?
                                    <Fragment>
                                        <Address 
                                            key={direccion.direccionRef}
                                            data={direccion.direccion} 
                                            esPrincipal={direccion.isPrincipal}
                                        />

                                        <div className="w-full text-center"> 
                                            <label className="text-xs">¿Necesitas corregir alguna dirección? </label>
                                            <Link to="/perfil" className="text-xs">Editala aquí</Link>
                                        </div>
                                        <div className="">
                                            <BasicButton type="button" clickFn={() => solicitarRenta(1)} text="Confirmar dirección y proceder" className="bg-primary-400 text-white text-center hover:bg-primary-500 text-base mt-5"/>
                                        </div>
                                    </Fragment>
                                :
                                    ''
                            }
                        </div>
                }
            </Modal>

            <Modal className="max-w-2xl" title="No solicitar servicio de flete" show={showNoSolicitarFlete} setShow={setShowNoSolicitarFlete}>
                <div className="mt-3 text-left">
                    Esta es la dirección del Rentero:
                    <p className="text-center text-base m-5">{grupo.direccion_rentero}</p>
                    Asegurate de que es posible para ti recoger los equipos en la dirección indicada. Toma en cuenta el tamaño de los equipos y del medio de transporte que utilizarás para moverlos.
                    <div className="">
                        <BasicButton type="button" clickFn={() => solicitarRenta(3)} text="Continuar sin servicio de flete" className="bg-secondary-400 text-white text-center hover:bg-secondary-500 text-base mt-5"/>
                    </div>
                </div>
            </Modal>

            <Modal className="max-w-2xl" title="Aceptar servicio de flete" show={showAceptarFlete} setShow={setShowAceptarFlete}>
                <div className="mt-3 text-left">
                    Al aceptar el precio del flete, éste pasará a ser parte del precio total de la renta.
                    <div className="">
                        <BasicButton type="button" clickFn={aceptarFlete} text="Aceptar y continuar" className="bg-primary-400 text-white text-center hover:bg-primary-500 text-base mt-5"/>
                    </div>
                </div>
            </Modal>

            <Modal className="max-w-2xl" title="Aceptar servicio de flete" show={showPago} setShow={setShowPago}>
                <div className="mt-3 text-left">
                    Estamos preparados para recibir tu pago, por favor, realiza el proceso de checkout en la siguiente liga.
                    <div className="">
                        <BasicButton type="button" clickFn={procesoCheckout} text="Ir a pagar" className="bg-primary-400 text-white text-center hover:bg-primary-500 text-base mt-5"/>
                    </div>
                </div>
            </Modal>

            <Modal className="max-w-2xl" title="Cancelar Solicitud" show={showCancelarRenta} setShow={setShowCancelarRenta}>
                <div className="mt-3 text-left">
                    Al cancelar la solicitud se interrumpe el proceso de renta y los equipos seleccionados se liberan para poder ser rentados en las fechas apartadas. <br/>
                    Podrás ver la solicitud ya cancelada en tu historial de rentas.
                    <div className="grid grid-cols-2 gap-x-3">
                        <BasicButton type="button" text="No cancelar mi renta" clickFn={() => showModal(() => setShowCancelarRenta(false))} className="bg-primary-400 text-white text-center hover:bg-primary-500 text-base col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 mt-5"/>
                        <BasicButton type="button" text="Sí cancelar mi renta" clickFn={cancelarRenta} className="bg-secondary-400 text-white text-center hover:bg-secondary-500 text-base col-span-2 md:col-span-1 lg:col-span-1 xl:col-span-1 xxl:col-span-1 mt-5"/>
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}

export default CartGroup;
