import React, { createContext, useState, useEffect }  from 'react';
import { Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';
import Login from './pages/login';
import Logout from './pages/logout';
import Registro from './pages/registro';
import IndexPage from './pages/index';
import QuienesSomos from './pages/quienes-somos';
import RentarEquipo from './pages/rentar-un-equipo';
import QuieroRentarMisEquipos from './pages/poner-en-renta-mis-equipos';
import AppMerent from './pages/app-merent';
import Contacto from './pages/contacto';
import Favoritos from './pages/favoritos';
import Operadores from './pages/operadores';
import ShoppingCart from './pages/shopping-cart';
import Perfil from './pages/perfil';
import { CustomProvider } from 'rsuite';
import esES from 'rsuite/locales/es_ES';
import ProfileContext from './components/Contexts/ProfileContext';
import Equipo from './components/UI/Templates/equipo-template';
import Operador from './components/UI/Templates/operador-template'
import { merent_instance } from './helpers/axios_helper';
import NotFoundPage from './pages/404';

function App() {
  const [profile, setProfile] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigation = useNavigate();
  const CalcularEquipo = () => {
    return (<Equipo/>)
  }
  return (
    <CustomProvider locale={esES}>
      <ProfileContext.Provider value={{profile, setProfile}}>
        <Routes>
          {/*Rutas Publicas */}
            <Route path="/login" element={<Login/>}></Route>
            <Route path="/login/:olvidaContrasena" element={<Login/>}></Route>
            <Route path="/registro" element={<Registro/>}></Route>
            <Route path="/logout" element={<Logout/>}></Route>
            <Route path="/" element={<IndexPage/>}></Route>
            <Route path="/index" element={<IndexPage/>}></Route>
            <Route path="/quienes-somos" element={<QuienesSomos/>}></Route>
            <Route path="/rentar-un-equipo" element={<RentarEquipo/>}></Route>
            <Route path="/poner-en-renta-mis-equipos" element={<QuieroRentarMisEquipos/>}></Route>
            <Route path="/app-merent" element={<AppMerent/>}></Route>
            <Route path="/contacto" element={<Contacto/>}></Route>
            <Route path="/favoritos" element={<Favoritos/>}></Route>
            <Route path="/operadores" element={<Operadores/>}></Route>
            <Route path="/shopping-cart" element={<ShoppingCart/>}></Route>
            <Route path="/perfil" element={<Perfil/>}></Route>
            <Route 
              path="/rentero/:id_rentero/equipo/:id_equipo" 
              element={<Equipo/>}
            />
            <Route 
              path="/operador/:id_operador" 
              element={<Operador/>}
            />
        </Routes>
      </ProfileContext.Provider>
    </CustomProvider>
  );
}

export default App;
