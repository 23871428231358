import React from 'react'
import { Disclosure } from '@headlessui/react';
import { FaChevronDown } from 'react-icons/fa';

function Compact({title, children}) {
    return(
        <Disclosure c>
            {({open}) => (
                <>
                    <Disclosure.Button className="flex mb-2 p-2 w-full justify-between rounded-lg bg-primary-100 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                        <span>{title}</span>
                        <FaChevronDown className={`${
                            open ? 'rotate-180 transform' : ''
                        } h-5 w-5 text-primary-500`}/>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                        {children}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

export default Compact;