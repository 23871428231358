import React from 'react';
import BasicButton from "../Buttons/BasicButton";
import { Link } from "react-router-dom";
import NotFound from "../../../images/image-not-found.png"
import Image from '../LazyLoadImage';


const ShoppingCartElement = ({title, image, link, description, precio, index, setNewEquipos, showRemove}) => {
    const borrarCarrito = () => {
        let carrito_actual = JSON.parse(localStorage.getItem("carrito"));
        carrito_actual = carrito_actual.filter((_, idx) => idx !== index);
        localStorage.setItem("carrito", JSON.stringify(carrito_actual));
        setNewEquipos(carrito_actual);
    }
    return(
            <div className={`grid grid-cols-6 md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-y-3 mb-1 px-3 text-base bg-gray-100 shadow-lg border-b-2 border-gray-200 py-2`}>
                <div className="col-span-1">
                    {
                    image !== undefined && image !== null && image !== "" ?
                        <Image
                            src={image.url}
                            alt="Imagen Equipo MeRent"
                            className={"object-cover h-100 w-screen m-0"}
                        />
                    :
                        <Image
                            src={NotFound}
                            alt="Imagen no encontrada"
                            className={"object-cover h-100 w-screen m-0"}
                        />
                    }
                </div>
                <div className="col-span-5 md:col-span-4 lg:col-span-4 xl:col-span-4 flex justify-center items-center">
                    <Link to={link} style={{ textDecoration: 'none' }}>{title}</Link>
                </div>
                <div className="col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-3 flex justify-center items-center">
                    {description}
                </div>
                <div className="col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 flex justify-center items-center">
                    ${precio}
                </div>
                {
                    showRemove ? 
                    <div className="col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 flex justify-center items-center">
                        <BasicButton type="button" text="Remover" clickFn={borrarCarrito} className="bg-primary-400 text-white text-center hover:bg-primary-500"/>
                    </div>
                    :
                    ''
                }
                
            </div>
        
    )
}

export default ShoppingCartElement;