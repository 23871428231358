import React, {Fragment, memo} from 'react';
import { CSVLink } from 'react-csv';
import { FaDownload } from 'react-icons/fa';
import { Grid } from 'gridjs-react';
import "gridjs/dist/theme/mermaid.css";
import { esES } from "gridjs/l10n";

const Datagrid = ({title = '', header, data}) => {
    const styles = {
        table: { 
            'white-space': 'nowrap'
        },
        th: {
            'background-color': '#FE8658',
            'color': '#fff',
            'text-align': 'center'
        },
        td: {
            'padding': '12px 10px 12px 10px'
        }
    };

    return (
        <Fragment>
            <div className='h-[430px]'>
                {
                    title !== '' ? 
                    <header className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-8 xl:grid-cols-6 gap-x-8 p-2">
                        <p className="col-span-1 lg:col-span-2 xl:col-span-1 text-3xl font-semibold my-3 text-center">
                            {title}
                        </p>
                    </header>
                    :
                        ''
                }
                <div className="w-full p-3">
                    <div className="w-full p-3 bg-white rounded-lg">
                        <Grid
                            data={data}
                            columns={header}
                            pagination={{
                                enabled: true,
                                limit: 5,
                            }}
                            style={styles}
                            fixedHeader={true}
                            language={esES}
                            resizable={false}
                            sort={true}
                        />
                        <CSVLink
                            className="flex rounded-lg bg-secondary-200 p-2 m-1 justify-center items-center text-white font-bold cursor-pointer hover:text-white"
                            data={data}
                            headers={header.map(c => ({ label: c.name, key: c.id }))}
                            filename={title+'.csv'}
                            enclosingCharacter={''}
                            separator={';'}>
                            <FaDownload className='inline text-[22px] ml-2 text-white'></FaDownload>
                            &#8203;
                            Descargar
                        </CSVLink>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default memo(Datagrid);

