import React, {Fragment} from 'react';
import { FaRegHeart, FaHeart } from "react-icons/fa";

const FavoriteButton = ({active, clickFn, className}) => {

    return(
        <Fragment>
        {
            active ? 
            <button type="button" onClick={clickFn} className={className}><FaHeart className="text-2xl text-primary-500"/></button>
            :
            <button type="button" onClick={clickFn} className={className}><FaRegHeart className="text-2xl text-secondary-200"/></button>
        }
        </Fragment>
    )
}

export default FavoriteButton;