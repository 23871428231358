import React from 'react';
import Rate from "../Rate";
import Image from '../LazyLoadImage';
import UserAvatar from '../../../images/User-avatar.png'

const Comment = ({name, rate, title, date, comment, image}) => {
    return(
        <div className={`mb-2 p-3 text-base bg-gray-50 shadow-lg border-b-2 border-gray-100 rounded-md`}>
            <div className="inline-block m-1 mr-2">
            {
                image !== undefined && image !== null && image !== "" ?
                    <Image
                        src={UserAvatar}
                        loading="eager"
                        formats={["auto", "webp", "avif"]}
                        alt={title}
                        style={{ width: "60px", borderRadius: "50%" }}
                    />
                :
                    <Image
                        src={UserAvatar}
                        loading="eager"
                        formats={["auto", "webp", "avif"]}
                        alt={title}
                        style={{ width: "60px", borderRadius: "50%", borderColor: "#fff", border: "2px solid #FE8658" }}
                    />
            }
            </div>
            {name}
            <Rate defaultValue={rate} readOnly={false} classNames="justify-start m-0"/>
            <h6>{title} <span className='text-[11px] text-secondary-100 font-light'>{date}</span></h6> 
            {comment}
        </div>
    )
}

export default Comment;