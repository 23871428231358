
export const grid_columns_rentas = [
    { id: 'numeroRenta', name: '#', width: '110px' },
    { id: 'estatus', name: 'Estado Actual', width: '200px' },
    { id: 'numeroEquipos', name: 'Equipos', width: '110px', sort: false },
    { id: 'rentero', name: 'Rentero', width: '180px' },
    { id: 'rangoFechas', name: 'Rango de fechas', width: '110px' },
    { id: 'costoFlete', name: '$ Flete', width: '140px' },
    { id: 'totalRenta', name: 'Total', width: '180px' },
    { id: 'fechaRenta', name: 'Fecha inicio', width: '180px' },
    { id: 'fechaRentaTerminada', name: 'Fecha fin', width: '180px' },
    { id: 'calificacion', name: 'Calificación', width: '150px' },
    { id: 'extenderRenta', name: 'Extender Renta', width: '150px' }
];

export const grid_columns_tickets = [
    { id: 'id', name: '#', width: '110px' },
    { id: 'rentaRef', name: '# Renta', width: '150px' },
    { id: 'motivo', name: 'Motivo', width: '110px' },
    { id: 'comentarios', name: 'Comentarios', width: '110px' },
    { id: 'fechaCreacion', name: 'Fecha de Creación', width: '110px'}
];

export const grid_columns_operadores = [
    { id: 'nombreOperador', name: 'Nombre', width: '300px' },
    { id: 'categoria', name: 'Categoria', width: '200px' },
    { id: 'telefono', name: 'Telefono', width: '140px' },
    { id: 'correoElectronico', name: 'Correo', width: '160px' },
    { id: 'localidad', name: 'Localidad', width: '200px' },
    { id: 'fechaContacto', name: 'Fecha de contacto', width: '140px' },
    { id: 'calificacion', name: 'Calificación', width: '150px' }
]