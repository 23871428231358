import React, {useState, useEffect} from "react";
import Layout from "../Layout";
import Container from "../Container";
import BasicButton from '../Buttons/BasicButton';
import Modal from '../Modal';
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import ThumbnailsGroupOperadores from "../Thumbnails/ThumbnailsGroupOperadores";
import Rate from "../Rate";
import { merent_instance } from "../../../helpers/axios_helper";
import toast from 'react-hot-toast';
import BasicInput from "../Inputs/BasicInput";
import { useParams } from "react-router-dom";
import Image from "../LazyLoadImage";
import UserAvatar from "../../../images/User-avatar.png"
 
const OperadorTemplate = () => {
    const {id_operador} = useParams();

    const [operadorInfo, setOperadorInfo] = useState({
        id: id_operador,
        nombre: "",
        precioDia: "100",
        precioSemana: "", 
        precioQuincena: "", 
        precioMes: "", 
        telefono: "",
        apellidos: "",
        calificacionGeneral: "",
        categoria: "",
        ciudad: "",
        descripcion: "",
        email: "",
        estado: "",
        imagenPerfil: {url: ""}
    })

    const [todosOperadores, setTodosOperadores] = useState([]);
    const [showDatosContacto, setShowDatosContacto] = useState(false);
    const [showContratacion, setShowContratacion] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState({});
    const [address, setAddress] = useState([]);
    const [habilidades, setHabilidades] = useState([]);

    useEffect(() => {
        cargarInfoOperador();
        cargarTodosOperadores();
        cargarHabilidades();
        cargarDirecciones();
    }, []);

    const cargarInfoOperador = () => {
        merent_instance().get(`/api/operador/catalogo/${operadorInfo.id}`).then(({data}) => {
            setOperadorInfo(data);
            console.log(data);
        }).catch((error) => {
            console.log(error);
            toast.error("Error al consultar habilidades del operador");
        })
    }

    const cargarTodosOperadores = () => {
        merent_instance().get("api/operador/catalogo").then(({data}) => {
            setTodosOperadores(data.filter(x => x.id != id_operador));
          }).catch((error) => {
            toast.error("Error al cargar equipos, intenta nuevamente más tarde");
          });
    }

    const cargarHabilidades = () => {
        merent_instance().get(`/api/operador/${operadorInfo.id}/habilidades`).then(({data}) => {
            setHabilidades(data);
        }).catch((error) => {
            console.log(error);
            toast.error("Error al consultar habilidades del operador");
        })
    }

    const cargarDirecciones = () => {
        const usr_id = localStorage.getItem("usr_id");

        merent_instance().get(`/api/clientes/${usr_id}/direcciones`).then(({data}) => {
          const objs_direccion = data.map(e => {
            return {
                value: e.id,
                label: `${e.direccion.nombre} - ${e.direccion.direccionCompleta} ${e.direccion.ciudad} ${e.direccion.estado} ${e.direccion.codigoPostal}`
            }
          });

          setAddress(objs_direccion);
        }).catch((error) => {
          toast.error("Ha habido un error al consultar sus direcciones")
        })
    }

    const solicitudContacto = () => {
        if(selectedAddress === "-1"){
            toast.error("Seleccioa una dirección para continuar");
            return;
        }
        const client_id = localStorage.getItem("usr_id");
        const operador_id = operadorInfo.id;
        const direccion = address.find(e => e.value == selectedAddress);
        const current_date = new Date();
        const form_data = {
            direccion: direccion.label,
            fechaContacto: current_date.toLocaleDateString("en-CA"),
            calificacion: 0.0,
            operadorRef: operador_id,
            clienteRef: client_id
        };

        merent_instance().post("api/intencioncontratacion", form_data).then(({data}) => {
            toast.success("Se ha mandado tu intención de contacto al operador");
            setShowContratacion(false);
            setShowDatosContacto(true);
        }).catch((e) => {
            console.log(e);
            toast.error("Hubo un error al solicitar el contacto, intenta nuevamente más tarde");
        })
    }

    return (
    <Layout>
        <Container> 
            <div className="relative overflow-hidden bg-white rounded-lg mt-3 shadow-lg py-3 px-5">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-8 gap-y-3">
                    <div className="flex flex-col">
                        <div className="flex flex-row">
                            <div className="bg-white w-[100px] h-[100px] m-3 truncate text-secondary-400">
                            {
                                operadorInfo.imagenPerfil !== undefined && operadorInfo.imagenPerfil !== null && operadorInfo.imagenPerfil !== "" ?
                                    <Image
                                        src={operadorInfo.imagenPerfil.url}
                                        alt={operadorInfo.nombre}
                                        style={{ width: "100%", borderRadius: "50%", borderColor: "#fff", border: "2px solid #FE8658"}}
                                    />
                                :
                                    <Image
                                        src={UserAvatar}
                                        alt={operadorInfo.nombre}
                                        style={{ width: "100%", borderRadius: "50%", border: "2px solid #FE8658" }}
                                    />
                            }
                            </div>
                            <div className="m-3">
                                <p className='w-full text-left text-2xl text-primary-500 font-bold px-2'>{operadorInfo.nombre} {operadorInfo.apellidos}</p>
                                <p className='w-full text-left text-lg text-secondary-500 m-0 px-2'>{operadorInfo.ciudad}, {operadorInfo.estado}</p>
                                <p className='w-full text-left text-lg text-secondary-500 font-bold m-0 px-2'>{operadorInfo.categoria}</p>
                                <Rate defaultValue={operadorInfo.calificacionGeneral} readOnly={false} classNames="justify-start mt-0 ml-0"/>
                            </div>
                        </div>
                        <div>
                            <p className='w-full text-left text-base text-secondary-500 px-2'>{operadorInfo.descripcion}</p>
                            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-x-2 gap-y-2 mt-5">
                                <div className="p-1 text-seconday-100 text-lg rounded font-bold text-center mb-2">
                                    <div className="text-sm font-thin">
                                        Día
                                    </div>
                                    $ {operadorInfo.precioDia}
                                </div>
                                <div className="p-1 text-seconday-100 text-lg rounded font-bold text-center mb-2">
                                    <div className="text-sm font-thin">
                                        Semana
                                    </div>
                                    $ {operadorInfo.precioSemana}
                                </div>
                                <div className="p-1 text-seconday-100 text-lg rounded font-bold text-center mb-2">
                                    <div className="text-sm font-thin">
                                        Quincena   
                                    </div>
                                    $ {operadorInfo.precioQuincena}
                                </div>
                                <div className="p-1 text-seconday-100 text-lg rounded font-bold text-center mb-2">
                                    <div className="text-sm font-thin">
                                        Mes
                                    </div>
                                    $ {operadorInfo.precioMes}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <section className="w-full p-2">
                            <p className='w-full text-left text-xl text-primary-500 font-bold p-2'>Habilidades</p>
                            <ul className="list-disc">
                                {
                                    habilidades.map((habilidad, index) => {
                                        return <li key={index}>{habilidad.nombre}</li>
                                    })
                                }
                            </ul>
                            
                            <BasicButton type="button" text="Ver datos de contacto" className="bg-primary-500 hover:bg-primary-400" clickFn={() => setShowContratacion(true)}/>
                        </section>
                    </div>
                </div>
            </div>

            <ThumbnailsGroupOperadores
                groupName={`Mas operadores de ${operadorInfo.categoria}`}
                description=''
                operadores={todosOperadores}
            />

            <Modal className="max-w-2xl" title={`Datos de contacto`} show={showDatosContacto} setShow={setShowDatosContacto}>
                <div className="mt-2 text-xl ">
                    <div className="w-full text-center"><strong className="text-primary-400 text-2xl">{operadorInfo.nombre} {operadorInfo.apellidos}</strong></div>
                    
                    <p className="my-1"><FaMapMarkerAlt className="text-primary-500 inline mr-2"/><strong className="text-primary-400">Localidad: </strong>{operadorInfo.ciudad}, {operadorInfo.estado}</p>
                    <p className="my-1"><FaPhone className="text-primary-500 inline mr-2"/><strong className="text-primary-400">Teléfono: </strong>{operadorInfo.telefono}</p>
                    <p className="my-1"><FaEnvelope className="text-primary-500 inline mr-2"/><strong className="text-primary-400">Correo: </strong> {operadorInfo.email}</p>
                </div>
            </Modal>
            <Modal className="max-w-2xl" title={`Solicitar contacto`} show={showContratacion} setShow={setShowContratacion}>
                <div className="mt-2 text-xl">
                    Se enviarán tus datos de contacto al operador
                </div>
                <div className="mt-2 text-base">
                    Selecciona la dirección para la que ocupas el servicio
                    <BasicInput
                        type="select"
                        className=""
                        value={selectedAddress}
                        setValue={(e) => setSelectedAddress(e.target.value)}
                        options={address}
                    />
                </div>
                <div className="mt-4 flex">
                    <BasicButton type="button" clickFn={solicitudContacto} text={"Solicitar contacto"}/>
                </div>
            </Modal>
        </Container>
    </Layout>
    )
}

export default OperadorTemplate;
